<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                type="text"
                class="form-control"
                placeholder="ค้นหาเบอร์ หรือ จำนวนเงิน"
                @keyup.enter="getSMS()"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">สถานะ</label>
              <b-form-select
                v-model="search_type"
                class="d-inline-block mr-1"
                :options="search_type_option"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true }"
              />
            </div>
          </div>
          <div class="col-md-3 mt-2">
            <button
              class="btn btn-gradient-dark"
              @click="getSMS()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
          </div>
        </template>
        <template #cell(topup)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="data.item.status === 'waiting'"
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="topup(data.item)"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="เติมเครดิต"
        ok-title="เติมเครดิต"
        @ok="submit"
      >
        <div class="d-flex">
          <b-form-input
            id="vi-first-name"
            v-model="tel"
            class="col-9 mr-1"
            name="tel"
            type="number"
            placeholder="กรอกเบอร์โทรศัพท์แล้วกดค้นหา"
          />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="col-2.5 btn-icon"
            block
            @click="getUsersSearch()"
          >
            <feather-icon icon="SearchIcon" />
            ค้นหา
          </b-button>
        </div>
        <div>
          <span
            v-if="!member && search === true"
            style="color :red;"
          >
            ไม่พบข้อมูล
          </span>
          <span
            v-if="member && search === true"
            style="color :green;"
          >
            {{ member.name }} {{ member.surname }} : {{ member.username }}
          </span>
        </div><br>
        <b-form-group
          label="จำนวนเงินที่ฝาก"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody, VBToggle, BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    flatPickr,
    // vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_type_option: [
        { value: null, text: 'ทั้งหมด' },
        { value: 'waiting', text: 'สถานะรอเติมเงิน' },
        { value: 'success', text: 'สถานะเติมเงินสำเร็จ' },
      ],
      search_type: null,
      search_val: null,
      show: true,
      time: '',
      depositdata: [],
      member: '',
      amount: '',
      trueData: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'sender', label: 'sender' },
        { key: 'tel', label: 'tel' },
        { key: 'amount', label: 'amount' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'topup', label: 'เติมเครดิต' },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      show2: false,
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD 00:00:00'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD 23:59:59'),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     this.getSMS()
  //   }, 60000)
  // },
  // beforeDestroy() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getSMS()
  },
  methods: {
    getUsersSearch() {
      this.show2 = true
      const obj = {
        tel: this.tel,
      }
      this.$http
        .post('/users/search', obj)
        .then(response => {
          this.member = response.data
          this.search = true
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    topup(data) {
      this.trueData = data
      this.amount = data.amount
      this.time = data.time
      // this.getUserslist()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    getSMS() {
      const params = {
        search_type: this.search_type,
        search_val: this.search_val,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/truewallet', { params })
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    submit() {
      if (!this.member.username) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'กรอกเบอร์ที่ต้องการเติมเครดิต',
            icon: 'SearchIcon',
            variant: 'warning',
          },
        })
      } else {
        this.show = true
        const formData = {
          username: this.member.username,
          uid: this.member.id,
          amount: this.amount,
          time: this.time,
          bank: 'truewallet',
        }
        this.$http
          .post('/deposit/store', formData)
          .then(() => {
            this.$http
            // eslint-disable-next-line no-underscore-dangle
              .get(`/truewallet/updatesuccess/${this.trueData._id}`)
              .then(() => {
                this.show = false
                this.getSMS()
              })
              .catch(error => console.log(error))
            this.username = ''
            this.amount = ''
            this.time = ''
            this.Success('เติมเครดิต สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
